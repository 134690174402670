import React from "react"

const Address = () => (
    <address className="text-center text-muted">
            8889 University Dr, Burnaby, BC V5A1S6<br/>
            Phones: (604) 262 - 5242<br/>
            Email: info@vankutech.com<br/>
            Bus Hours: 9:00AM - 5:00PM<br/>
    </address>
)

export default Address