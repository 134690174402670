import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Figure } from "react-bootstrap";
import Address from "../components/address";

const ServicesPage = () => (
  <Layout>
    <SEO title="Services" keywords={[`Angular`, `Zend Frameworks`, `SugarCRM`, `Docker Container`, `AWS`, `ReactJS`, `chatGPT`]}/>
   
      <Container>
        <br/>
        <p className="lead">We specialize in three key business software development areas:</p>
        <ol className="lead">
          <li>Custom Software Development
          <br/>We offer full-cycle development services for online Web applications.</li>
          <li>Software Consulting
          <br/>We deliver expert level software consulting services, ranging from concept formulation, requirements analysis and new technology training.
          </li>
          <li>Web Portals
          <br/>Our expert level design, development and integration will make your web portal project well suited for your online customers' needs.
          </li>
          <li>OpenAI
            <br/>We provide chatGPT based on OpenAI solutions including ChatBot, Data Classification and Knowledge Generation.
          </li>
          <li>Data Warehouse
            <br/>We provide cloud based data warehouse solutions for midium and small enterprises.
          </li>
        </ol>
       
        <p className="lead">The technologies we adopt include but not limited to,</p>
      
     
      <Row>
        <Col md={4}>
        <Figure>
          <Figure.Image
            width={171}
            height={180}
            alt="angular"
            src="/img/angular.png"
          />
          <Figure.Caption>
            Angular framework for Single Page applications
          </Figure.Caption>
        </Figure>
        </Col>
        <Col md={4}>
        <Figure>
          <Figure.Image
            width={171}
            height={180}
            alt="react"
            src="/img/react.png"
          />
          <Figure.Caption>
            React framework for front end UI
          </Figure.Caption>
        </Figure>
        </Col>
        <Col md={4}>
        <Figure>
          <Figure.Image
            width={171}
            height={180}
            alt="zend"
            src="/img/zend.png"
          />
          <Figure.Caption>
            Zend framework for Web Applications and API
          </Figure.Caption>
        </Figure>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
        <Figure>
          <Figure.Image
            width={171}
            height={180}
            alt="sugarCRM"
            src="/img/sugarcrmIcon.png"
          />
          <Figure.Caption>
           SugarCRM/SuiteCRM installation and customization
          </Figure.Caption>
        </Figure>
        </Col>
        <Col md={4}>
        <Figure>
          <Figure.Image
            width={171}
            height={180}
            alt="magento"
            src="/img/magento.png"
          />
          <Figure.Caption>
          Magento eCommerce installation and customization
          </Figure.Caption>
        </Figure>
        </Col>
        <Col md={4}>
        <Figure>
          <Figure.Image
            width={171}
            height={180}
            alt="aws"
            src="/img/aws.png"
          />
          <Figure.Caption>
          Customized solutions for AWS cloud services
          </Figure.Caption>
        </Figure>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Figure>
            <Figure.Image
              width={171}
              height={180}
              alt="nodejs"
              src="/img/nodejs.png"
            />
            <Figure.Caption>
            Applications based on NodeJS and Express Framework
            </Figure.Caption>
          </Figure>
        </Col>
        <Col md={4}>
          <Figure>
            <Figure.Image
              width={171}
              height={180}
              alt="chatGPT"
              src="/img/chatGPT.png"
            />
            <Figure.Caption>
            OpenAI solutions for enterprises applications
            </Figure.Caption>
          </Figure>
        </Col>
        <Col md={4}>
          <Figure>
            <Figure.Image
              width={171}
              height={180}
              alt="Data Warehouse"
              src="/img/dataWarehouse.png"
            />
            <Figure.Caption>
            Data Warehouse for business strategic planning
            </Figure.Caption>
          </Figure>
        </Col>
      </Row>

    </Container>
    <Address/>
  </Layout>
)

export default ServicesPage